import React, { useEffect } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionListingCarousel.module.css';

const COLUMN_CONFIG = [
  {
    css: css.oneColumn,
    responsiveImageSizes: '(max-width: 767px) 100vw, 1200px',
  },
  {
    css: css.twoColumns,
    responsiveImageSizes: '(max-width: 767px) 100vw, 600px',
  },
  {
    css: css.threeColumns,
    responsiveImageSizes: '(max-width: 767px) 100vw, 400px',
  },
  {
    css: css.fourColumns,
    responsiveImageSizes: '(max-width: 767px) 100vw, 290px',
  },
];

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

const getIndex = numColumns => (numColumns = 1);
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};

const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

const SectionListingCarousel = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    options,
  } = props;

  const sliderContainerId = `${sectionId}-container`;
  const sliderId = `${sectionId}-slider`;
  const numberOfBlocks = blocks?.length;
  const hasBlocks = numberOfBlocks > 1;

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };

    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, []);

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const hasHeaderFields = hasDataInFields([title, description, callToAction]);

  const onSlideLeft = e => {
    const slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    e.target.focus();
  };

  const onSlideRight = e => {
    const slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      e.preventDefault();
      onSlideRight(e);
    }
  };

  return (
    <SectionContainer
      appearance={appearance}
      className={className}
      id={sectionId}
      options={fieldOptions}
      rootClassName={rootClassName}
    >
      {/* Header */}
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field className={defaultClasses.title} data={title} options={fieldOptions} />

          <Field className={defaultClasses.description} data={description} options={fieldOptions} />

          <Field className={defaultClasses.ctaButton} data={callToAction} options={fieldOptions} />
        </header>
      ) : null}

      {/* Body */}
      {hasBlocks ? (
        <div className={css.carouselContainer} id={sliderContainerId}>
          <div
            className={classNames(css.carouselArrows, {
              [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
            })}
          >
            <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
              ‹
            </button>

            <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
              ›
            </button>
          </div>

          <div className={getColumnCSS(numColumns)} id={sliderId}>
            <BlockBuilder
              blocks={blocks}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
              responsiveImageSizes={getResponsiveImageSizes(numColumns)}
              rootClassName={css.block}
              sectionId={sectionId}
            />
          </div>
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({
    component: node,
    pickValidProps: func,
  }),
});

SectionListingCarousel.defaultProps = {
  appearance: null,
  blocks: [],
  callToAction: null,
  className: null,
  defaultClasses: null,
  description: null,
  numColumns: 1,
  options: null,
  rootClassName: null,
  textClassName: null,
  title: null,
};

SectionListingCarousel.propTypes = {
  appearance: object,
  blocks: arrayOf(object),
  callToAction: object,
  className: string,
  defaultClasses: shape({
    ctaButton: string,
    description: string,
    sectionDetails: string,
    title: string,
  }),
  description: object,
  numColumns: number,
  rootClassName: string,
  sectionId: string.isRequired,
  title: object,
  options: propTypeOption,
};

export default SectionListingCarousel;
