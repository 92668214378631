// Packages
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

// Local
import { Hero, IconSpinner, LayoutComposer, Trustpilot } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';
import { validProps } from './Field';
import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';
import css from './PageBuilder.module.css';
import { landingHeroImage } from '../../config/configBranding.js';
import IntercomLauncher from '../../components/IntercomLauncher/IntercomLauncher.js';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  // Props
  const {
    currentPage,
    error,
    fallbackPage,
    inProgress,
    options,
    pageAssetsData,
    schemaType,
    ...pageProps
  } = props;

  // Hooks
  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(pathname === '/');

  // Fallback UI
  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  useEffect(() => {
    setIsHome(pathname === '/');
  }, [pathname]);

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const HomepageHeroMarkdown = () => (
    <Hero
      appearance={{
        backgroundImage: {
          alt: 'Background image for landing page hero',
          loading: false,
          heigth: 400,
          src: landingHeroImage,
          width: 800,
        },
        backgroundImageOverlay: {
          color: '#000000',
          opacity: 0.3,
          preset: 'dark',
        },
        textColor: '#ffffff',
      }}
      callToActions={[
        {
          content: 'Sell my bike',
          href: '/l/new',
        },
        {
          content: 'Browse bikes',
          href: '/s',
        },
      ]}
      className={css.homeHero}
      defaultClasses={{
        ctaButton: css.heroCtaButton,
        description: css.heroDescription,
        sectionDetails: css.heroSectionDetails,
        title: css.heroTitle,
      }}
      description={{
        content: 'Sell with no fees. Buy with Buyer Protection',
        fieldType: 'paragraph',
      }}
      title={{
        content: 'Buy and Sell Pre-Loved Bikes with Moose Marketplace',
        fieldType: 'h1',
      }}
    />
  );

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
              </Topbar>

              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {isHome ? <HomepageHeroMarkdown /> : null}
                    <SectionBuilder sections={sections} options={options} />
                    <div className={css.trustpilotContainer}>
                      <div className={css.trustpilotInner}>
                        <h4>
                          <FormattedMessage id="PageBuilder.trustpilotTitle" />
                        </h4>
                        <Trustpilot theme="light" variant="carousel" />
                      </div>
                      <IntercomLauncher />
                    </div>
                  </>
                )}
              </Main>

              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;
